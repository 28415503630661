import React from "react"
import {
  Container,
  Main,
  Cutin,
  Content,
  Contact,
  Iframely,
  Seo,
  FeatureImage,
} from "../components"
import { FixedPageH1, FixedPageH2, P } from "../elements"
import { graphql } from "gatsby"
import Img from "gatsby-image"

const galleryPage = ({ data, location }) => {
  return (
    <Container>
      <Seo
        pagetitle="制作実績"
        pagedesc="今までマルスエが手掛けてきた制作物の一例ページです。"
        pagepath={location.pathname}
        pageimg={data.urushipresso.childImageSharp.original.src}
        pageimgw={data.urushipresso.childImageSharp.original.width}
        pageimgh={data.urushipresso.childImageSharp.original.height}
      />
      <Iframely />
      <FeatureImage fixed={data.urushipresso.childImageSharp.fixed} />
      <Cutin>
        <Main>
          <FixedPageH1>
            <h1>制作実績</h1>
          </FixedPageH1>
          {/* <Content>
            <ImgViewer>
              <div className="imageList__thumbnail">
                <img
                  src={data.urushipresso.childImageSharp.original.src}
                  alt=""
                />
              </div>
              <div className="imageList__thumbnail">
                <img src={data.wall.childImageSharp.original.src} alt="" />
              </div>
              <div className="imageList__thumbnail">
                <img
                  src={data.urushipresso.childImageSharp.original.src}
                  alt=""
                />
              </div>
              <div className="imageList__thumbnail">
                <img src={data.wall.childImageSharp.original.src} alt="" />
              </div>
              <div className="imageList__thumbnail">
                <img
                  src={data.urushipresso.childImageSharp.original.src}
                  alt=""
                />
              </div>
            </ImgViewer>
          </Content>
          <Content>
            <ImgViewer>
              <div className="imageList__thumbnail">
                <img
                  src={data.urushipresso.childImageSharp.original.src}
                  alt="chasso"
                />
              </div>
              <div className="imageList__thumbnail">
                <img src={data.wall.childImageSharp.original.src} alt="" />
              </div>
              <div className="imageList__thumbnail">
                <img
                  src={data.urushipresso.childImageSharp.original.src}
                  alt=""
                />
              </div>
              <div className="imageList__thumbnail">
                <img src={data.wall.childImageSharp.original.src} alt="" />
              </div>
              <div className="imageList__thumbnail">
                <img
                  src={data.urushipresso.childImageSharp.original.src}
                  alt=""
                />
              </div>
            </ImgViewer>
          </Content> */}
          <Content>
            <FixedPageH2>二尺二寸奥深型（伝産指定品）</FixedPageH2>
            <Img
              fluid={data.densan1.childImageSharp.fluid}
              loading="eager"
              durationFadeIn={100}
            />
            <P margin="1rem">
              仕様：　名古屋仏壇伝統的工芸品・奥深型
              <br />
              彫刻：　東本願寺阿弥陀堂の欄間を完全再現
              <br />
              塗装：　堅地｜泥下地｜総うるし
              <br />
              金箔：　金沢金箔１号
              <br />
              解説：
              <br />
              仏間のデッドスペースを限りなくなくし、外寸のわりに中に大きくスペースを取ることで、荘厳さ・お給仕のしやすさを実現。彫刻は京都東別院阿弥陀堂の欄間を完全に再現。
              <br />
              <br />
            </P>
          </Content>
          <Content>
            <FixedPageH2>
              家具調風金仏壇（地域ブランド「名古屋仏壇」）
            </FixedPageH2>
            <Img
              fluid={data.singata2.childImageSharp.fluid}
              loading="eager"
              durationFadeIn={100}
            />
            <P margin="1rem">
              仕様：　洋室向け名古屋仏壇
              <br />
              素材：　国産ヒノキ
              <br />
              外装塗装：　ウレタン塗料
              <br />
              内装塗装：　カシュー樹脂塗料
              <br />
              金箔：　金沢金箔４号
              <br />
              解説：
              <br />
              外側は「家具調仏壇」、 開くと中は「金仏壇」というデザインです。
              <br />
              「家具調のような、部屋に合うデザインにしたい」
              <br />
              「でも、家具調仏壇は、ありがたみがない」
              <br />
              「洋室に合うのと、お寺の荘厳さを両立出来ないか？」
              <br />
              という要望のもと、製作しました。
              <br />
            </P>
          </Content>
          <Content>
            <FixedPageH2>ウルシプレッソ</FixedPageH2>
            <Img
              fluid={data.urushipresso.childImageSharp.fluid}
              loading="eager"
              durationFadeIn={100}
            />
            <P margin="1rem">
              素材：　ステンレス
              <br />
              塗装：　漆塗り｜呂色仕上げ
              <br />
              装飾：　沈金
              <br />
              解説：
              <br />
              イタリアのエスプレッソマシンメーカー「LA
              MARZOCCO」のエスプレッソマシンを伝統工芸で装飾した、特別仕様機。シックな黒艶鏡面塗りに、伝統的な「鳳凰」がコーヒーの実を運んでいるデザインを沈金で表現。
              <br />
              <br />
            </P>
          </Content>
          <Content>
            <FixedPageH2>漆塗りパーティション</FixedPageH2>
            <Img
              fluid={data.wall.childImageSharp.fluid}
              loading="eager"
              durationFadeIn={100}
            />
            <P margin="1rem">
              素材：　ステンレス
              <br />
              塗装：　漆塗り｜変わり塗り
              <br />
              解説：
              <br />
              ヒルトン名古屋のエントランス・パーティションを漆塗りで表現。デザインテーマは「ふとん」ステンレスにFRPをピラミッド状に貼り、塗りに膨らみを持たせている。200枚製作
              <br />
              <br />
            </P>
          </Content>
          <Content>
            <FixedPageH2>千手観音修復（四尺三寸）</FixedPageH2>
            <Img
              fluid={data.senjukannon.childImageSharp.fluid}
              loading="eager"
              durationFadeIn={100}
            />
            <P margin="1rem">
              名称：　千手観音（飛鳥時代の作と推測）
              <br />
              塗装：　堅地｜漆塗り｜呂色仕上げ
              <br />
              金箔：　金沢金箔１号縁付
              <br />
              解説：
              <br />
              推定飛鳥時代から続く千手観音（解体時銘が出なかったので、詳細不明）を解体・木地直し・塗り直し修復。長い年月を経て移動のたびに改造されたようで、欠品パーツが多数。台座の一部・後光を新調塗装。金箔は１号縁付をうるし押し。
              <br />
              <br />
            </P>
          </Content>
          <Content>
            <FixedPageH2>御神輿修復</FixedPageH2>
            <Img
              fluid={data.mikoshi.childImageSharp.fluid}
              loading="eager"
              durationFadeIn={100}
            />
            <P margin="1rem">
              名称：　唐破風屋根型神輿
              <br />
              塗装：　漆塗り（金梨地）｜呂色仕上げ｜摺漆
              <br />
              解説：
              <br />
              御神輿の修復。元は錫梨地といって、錫のフレークを散りばめた塗りだったが、変色するデメリットが気になるという要望で金梨地（金のフレークを使う。変色しない）を施工。欅部分・彫刻は摺漆で防汚・防水加工。金具金メッキなおし。
              <br />
              <br />
              <br />
            </P>
            <FixedPageH2>「拾」オブジェ</FixedPageH2>
            <Img
              fluid={data.obj.childImageSharp.fluid}
              loading="eager"
              durationFadeIn={100}
            />
            <P margin="1rem">
              素材：　MDF
              <br />
              塗装：　カシュー（螺鈿・梨地）
              <br />
              金箔：　玉虫箔（銀） 解説：
              <br />
              テアワセの「拾」ロゴオブジェ。複数枚のMDF立体ロゴをカシュー変わり塗りして組み合わせ、表裏一枚目を玉虫箔（銀箔を化学変化で変色させた箔）で箔押し。
              <br />
            </P>
          </Content>
          <Content>
            <FixedPageH2>お問い合わせ</FixedPageH2>
            <Contact />
            <div className="iframely-embed">
              <div
                className="iframely-responsive"
                style={{ height: 140, paddingBottom: 0 }}
              >
                <a
                  href="https://www.google.com/maps/search/?api=1&query=%E3%83%9E%E3%83%AB%E3%82%B9%E3%82%A8%E4%BD%9B%E5%A3%87"
                  data-iframely-url="//cdn.iframe.ly/api/iframe?url=https%3A%2F%2Fwww.google.com%2Fmaps%2Fsearch%2F%3Fapi%3D1%26query%3D%E3%83%9E%E3%83%AB%E3%82%B9%E3%82%A8%E4%BD%9B%E5%A3%87&key=bbb152fda61816746702349ee0e12e2b"
                  aria-label="GoogleMaps"
                >
                  {null}
                </a>
              </div>
            </div>
          </Content>
        </Main>
      </Cutin>
    </Container>
  )
}

export const query = graphql`
  query {
    urushipresso: file(relativePath: { eq: "urushipresso.jpg" }) {
      childImageSharp {
        original {
          height
          src
          width
        }
      }
    }
    wall: file(relativePath: { eq: "wall.jpg" }) {
      childImageSharp {
        original {
          height
          src
          width
        }
      }
    }
    urushipresso: file(relativePath: { eq: "urushipresso.jpg" }) {
      childImageSharp {
        original {
          height
          src
          width
        }
      }
    }
    urushipresso: file(relativePath: { eq: "urushipresso.jpg" }) {
      childImageSharp {
        fixed(width: 1080, quality: 50) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    urushipresso: file(relativePath: { eq: "urushipresso.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 30) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    wall: file(relativePath: { eq: "wall.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 30) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    densan1: file(relativePath: { eq: "densan1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 30) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    singata2: file(relativePath: { eq: "singata2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 30) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    senjukannon: file(relativePath: { eq: "senjukannon.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 30) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mikoshi: file(relativePath: { eq: "mikoshi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 30) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    obj: file(relativePath: { eq: "obj.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 30) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default galleryPage
